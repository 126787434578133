.container {
  padding: 2rem 0;

  h1 {
    text-transform: uppercase;
    font-family: var(--main-font);
    font-style: normal;
    font-weight: bold;
    font-size: 55px;
    line-height: 57px;
    text-align: center;
    color: #ffffff;
    margin: 0;
    margin-bottom: 2rem;
  }

  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 1rem;
    row-gap: 1rem;
    max-width: 1080px;
    margin: auto;

    @media (max-width: 1000px) {
      grid-template-columns: 1fr 1fr;
    }

    @media (max-width: 650px) {
      grid-template-columns: 1fr;
    }

    .gridItem {
      .topRowItems {
        display: flex;
        justify-content: center;
        align-content: center;
      }

      h5 {
        font-family: var(--main-font);
        font-size: 1.5rem;
        margin: 1rem 0;
        display: inline-block;
      }
      p {
        text-align: left;
        font-family: var(--main-font);
        font-weight: 500;
        font-size: 1.2rem;
        margin-top: 0;
        padding: 0 0.5rem;
      }

      .iconTwit {
        width: 25px;
        margin: 0 0rem 0 1rem;
      }
      .iconInsta {
        width: 25px;
        margin: 0 0rem 0 1rem;
      }

      .image {
        width: 100%;
      }

      a {
        cursor: pointer;
        transition: transform 0.1s ease-out;
        display: flex;
        justify-content: center;
        align-content: center;

        img {
          transition: transform 0.1s ease-out;
          &:hover {
            transform: scale(1.07);
          }
        }
      }
      @media (max-width: 650px) {
        margin: 20px;
      }
    }

    .gridItemMain {
      grid-column: 1/4;
      grid-row: 1/2;

      @media (max-width: 1000px) {
        grid-column: 1/3;
      }

      @media (max-width: 650px) {
        grid-column: 1/2;
        margin: 20px;
      }

      a {
        cursor: pointer;
        transition: transform 0.1s ease-out;

        img {
          transition: transform 0.1s ease-out;
          &:hover {
            transform: scale(1.07);
          }
        }
      }

      h5 {
        font-family: var(--main-font);
        font-size: 1.5rem;
        margin: 1rem 0;
        display: inline-block;
      }
      p {
        text-align: left;
        font-family: var(--main-font);
        font-weight: 500;
        font-size: 1.2rem;
        margin-top: 0;
        padding: 0 0.5rem;
      }

      .iconTwit {
        width: 25px;
        margin: 0 0rem 0 1rem;
      }
      .iconInsta {
        width: 25px;
        margin: 0 0rem 0 1rem;
        padding-top: 0.2rem;
      }

      .image {
        max-width: 50%;
        @media (max-width: 650px) {
          max-width: 100%;
        }
      }
    }

    .gridItemMain2 {
      grid-column: 1/4;
      grid-row: 4/5;

      @media (max-width: 1000px) {
        grid-column: 1/3;
        grid-row: 6/7;
      }

      @media (max-width: 650px) {
        grid-column: 1/2;
        margin: 20px;
      }

      a {
        cursor: pointer;
        transition: transform 0.1s ease-out;

        img {
          transition: transform 0.1s ease-out;
          &:hover {
            transform: scale(1.07);
          }
        }
      }

      h5 {
        font-family: var(--main-font);
        font-size: 1.5rem;
        margin: 1rem 0;
        display: inline-block;
      }
      p {
        text-align: left;
        font-family: var(--main-font);
        font-weight: 500;
        font-size: 1.2rem;
        margin: auto;
        margin-top: 0;
        padding: 0 0.5rem;

        max-width: 70%;

        @media (max-width: 1000px) {
          max-width: 100%;
        }
      }

      .iconTwit {
        width: 25px;
        margin: 0 0rem 0 1rem;
      }
      .iconInsta {
        width: 25px;
        margin: 0 0rem 0 1rem;
        padding-top: 0.2rem;
      }

      .image {
        max-width: 40%;
        @media (max-width: 1000px) {
          max-width: 50%;
        }
        @media (max-width: 650px) {
          max-width: 100%;
        }
      }
    }
  }

  .divider {
    opacity: 1;
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
      rotateY(25deg) rotateZ(0deg) skew(0deg, 0deg);
    transform-style: preserve-3d;
    width: 100%;
    height: 1px;
    background-color: white;
    margin-top: 20px;
  }
}
