$darkblue: rgb(40, 40, 40);
$offwhite: darken(white, 10%);

.container {
  z-index: 2;
  position: fixed;
  top: 0;
  height: auto;
  width: 100%;
  font-family: var(--main-font);
  text-transform: uppercase;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 0;

  .navContainer {
    display: flex;
    font-size: .7rem;
    text-transform: uppercase;
    @media (max-width: 850px) {
      display: none;
    }
    ul {
      list-style: none;
      display: flex;
      padding: 0;
      @media (max-width: 500px) {
        padding-left: 0;
      }
      li {
        margin: 20px;
        button {
          font-family: var(--main-font);
          text-transform: uppercase;
          position: relative;
          cursor: pointer;
          background-color: transparent;
          color: white;
          font-weight: bold;
          box-shadow: none;
          font-size: 18px;
          outline: none;
          border-radius: 3px;
          padding: 0;
          transition: all 0.2s ease-out;
          @media (max-width: 500px) {
            font-size: 12px;
            width: 90px;
            height: 30px;
            padding: 0;
          }
          &:hover {
            color: #a933cf;
          }

        }
      }
    }
    h1 {
      display: flex;
      margin: 2rem;
    }


    a {
      text-decoration: none;
      color: white;
      font-size: 18px;
      font-weight: 600;
      display: flex;

      @media (max-width: 500px) {
        font-size: 1rem;
        line-height: 17px;
      }

      div {
        //padding: 4px 48px;
        transition: all 0.3s ease-out;

        @media (max-width: 500px) {
          padding: 5px 18px;
        }

        &:hover {
          color: #a933cf;
        }
      }
    }
  }

  .logoContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 32px;
    z-index: 5;
    @media (max-width: 850px) {
      margin-left: 30px;
    }
    img {
      width: 250px;
      height: 100px;
      @media (max-width: 500px) {
        width: 150px;
      }
    }
  }
  .launchButtonContainer{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .launchAppButton {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 150px;
    border: 2px solid white;
    border-radius: 0.5rem;
    margin: 5px;
    margin-right: 32px;
    transition: all 0.3s ease-out;
    @media (max-width: 850px) {
      display: none;
    }
    a {
      width: 100%;
      height: 100%;
      cursor: pointer;
      color: white;
      font-family: var(--main-font);
      font-size: 1rem;
      font-weight: bold;
      text-transform: uppercase;
      text-decoration: none;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &:hover{
      border: 2px solid #a933cf;
      background-color: #a933cf;
    }
    &:hover a{
      color: white;
    }
  }

}

.close {
  position: absolute;
  right: 0;
  padding: 0 2rem;
  img {
    height: 30px;
    width: 30px;
  }
}

.menuIcon {
  display: none;
  cursor: pointer;
  @media (max-width: 850px) {
    display: flex;
    margin-right: 30px;
  }
}

.menuCloseIcon {
  cursor: pointer;
  height: 25px;
  width: 25px;
  z-index: 5;
  margin-right: 30px;
}

