.loader {
  position: relative;
  top: 220px;
  z-index: 5;
  color: white;
  animation: fadeIn 1s infinite alternate;
}

.loaderImg {
  width: 250px;
  height: 250px;
  animation: spin 1s infinite alternate;
}

.loaderText {
  font-family: var(--main-font);
  font-weight: 600;
  font-size: 24px;
  top: -40px;
  right: -7px;
  position: relative;
}

@keyframes fadeIn {
  from {
    opacity: 0.3;
  }
}

@keyframes spin {
  0% {
    opacity: 0.3;
    transform: scale(-1, 1);
  }
  100% {
    // transform: rotate(359deg);
  }
}
#star {
  animation: spin 2s linear infinite;
}
