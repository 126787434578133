.section {
  display: flex;
  justify-content: center;
  align-items: center;
  width:100%;
  height:300px;
  background-image: url("../assets/stars_purple.jpg");
  background-size: cover;

  font-family: var(--main-font);
  font-weight: bold;
  font-style: normal;
  margin-bottom: 50px;
}

.divider{
  opacity: 1;
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(
                  0deg) rotateY(
                  0deg) rotateZ(
                  0deg) skew(
                  0deg,
                  0deg);
  transform-style: preserve-3d;
  width: 100%;
  height: 1px;
  background-color: white;
  margin-top: 20px;
}

.copyrightContainer {
  margin-bottom: 70px;
}

.copyright{
  margin-top: 20px;
}