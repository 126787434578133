.section {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: 500px;
  background-color: #0f0f0f;
  font-family: var(--main-font);
  font-weight: bold;
  font-style: normal;

  @media (max-width: 800px) {
    align-items: center;
    flex-direction: column;
    height: 800px;
  }
}

.emailSection {
  width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  #discord {
    margin-bottom: 0.25rem;
  }
}

.emailSection h1 {
  margin: 0;
}

.emailSection h4 {
  margin: 0;
  color: #cecece;
}

.emailSection form {
  display: flex;
  flex-direction: column;
}

.emailSection input[type="submit"] {
  font-family: var(--main-font);
  text-transform: uppercase;
  position: relative;
  background-color: rgb(44, 44, 44);
  color: #cecece;
  font-weight: bold;
  border: 3px solid white;
  border-bottom: hidden;
  border-right: hidden;
  overflow: hidden;
  box-shadow: none;
  font-size: 18px;
  transition: color 0.5s, transform 0.2s, background-color 0.2s;
  outline: none;
  cursor: pointer;
  border-radius: 3px;
  padding: 6px 27px;
  width: 280px;
  justify-self: center;
  align-self: center;

  margin-top: 1rem;

  @media (max-width: 500px) {
    font-size: 16px;
    // width: 90px;
    // height: 30px;
    padding: 6px 54px;
  }
  &:hover {
    color: white;
    // border-right: 5px solid white;
    // border-top: 4px solid white;
    transform: scale(1.04);
  }

  &:active {
    transform: translateY(0);
  }
}

.emailSection input[type="text"],
select {
  padding: 12px 20px;
  margin: 10px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  width: 80%;
  justify-self: center;
  align-self: center;
}

.joinDiscord {
  width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.discordButtonContainer {
  padding-top: 52px;
}

.joinDiscord h1 {
  margin: 0;
}

.joinDiscord h4 {
  color: #cecece;
  margin: 0;
  width: 50%;
}

.joinDiscordButton {
  display: flex;
  font-family: var(--main-font);
  text-transform: uppercase;
  text-decoration-line: none;
  position: relative;
  background-color: #5865f2;
  color: #cecece;
  font-weight: bold;
  border: 3px solid white;
  border-bottom: hidden;
  border-left: hidden;
  overflow: hidden;
  box-shadow: none;
  font-size: 18px;
  transition: all 0.2s ease-out;
  cursor: pointer;
  border-radius: 3px;
  padding: 6px 54px;

  @media (max-width: 500px) {
    font-size: 16px;
    // width: 90px;
    // height: 30px;
    padding: 6px 54px;
  }

  &:hover {
    // background-color: rgb(44, 44, 44);
    color: white;
    // border-right: 5px solid white;
    // border-top: 4px solid white;
    transform: scale(1.04);
  }

  &:active {
    transform: translateY(0);
  }
}
