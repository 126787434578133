.outer {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(0, 0, 0);
  z-index: -1;
  opacity: 1;
  background-image: url("../assets/stars_purple.jpg");
  background-size: cover; /* Resize the background image to cover the entire container */

  transition: opacity 0.5s ease-out, transform 0.5s ease-in-out;
}

.show {
  opacity: 1;
  z-index: 1;
  transform: translateX(0);
}

.hide {
  z-index: 1;
  transform: translateX(-100%);
}

.cover {
  width: 100%;
  height: 100%;
  color: #fff;
  overflow: hidden;
  z-index: 1;
  padding: 5rem 0;
  overflow-y: scroll;
  margin: auto;
}
.grid {
  display: grid;
  width: 100%;
  max-width: 1400px;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: max-content 5fr;
  margin: 2rem auto;
  margin-bottom: 4rem;
  @media (max-width: 800px) {
    margin-bottom: 11rem;
  }

  h4 {
    font-family: var(--main-font);
    font-style: normal;
    font-weight: bold;
    font-size: 27px;
    line-height: 33px;
    text-align: center;

    color: #ffffff;
    margin: 0;
    padding-bottom: 0.5rem;
    border-bottom: 3px solid white;
    @media (max-width: 520px) {
      font-size: 25px;
      line-height: 35px;
    }
  }

  p {
    font-family: var(--main-font);
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 38px;
    text-align: left;
    padding: 2rem 2rem;
    margin: 0;
    color: #cecece;
    @media (max-width: 800px) {
      padding: 1rem 1rem;
    }
    @media (max-width: 520px) {
      font-size: 18px;
      line-height: 30px;
      padding-right: 0;
    }
  }

  .imageCenter {
    height: 94.8%;
    width: 100%;
    grid-column: 3/11;
    background-image: url("../assets/roadmap_sprial_low_res.png");
    grid-row: 1/3;
    background-position: center;
    background-size: contain;
    background-repeat: repeat-y;
    margin-top: 200px;

    @media (max-width: 800px) {
      grid-column: 8/13;
      grid-row: 1/3;
    }
  }

  .titleSection {
    grid-column: 1/13;
    grid-row: 1/2;
    padding: 2rem 0;
    color: white;
    z-index: 1;
    background-color: transparent;

    h1 {
      font-family: var(--main-font);
      font-style: normal;
      font-weight: bold;
      font-size: 55px;
      line-height: 57px;
      text-align: center;

      color: #ffffff;
      margin: 0;
    }

    p {
      text-align: center;
      border-left: none;
    }
  }

  .col1 {
    display: grid;
    grid-column: 1/5;
    grid-row: 2/3;
    margin-left: 32px;
    // grid-template-rows: 0.5fr 0.9fr 0.8fr 0.8fr 1fr 0.8fr 1fr 1fr;
    grid-template-rows: repeat(9, 1fr);
    @media (max-width: 800px) {
      grid-column: 1/8;
      grid-row: 2/3;
      margin-left: 8px;
    }
    p {
      border-left: 3px solid white;
      @media (max-width: 800px) {
      }
    }
  }

  .col2 {
    display: grid;
    grid-column: 9/13;
    grid-row: 2/3;
    grid-template-rows: repeat(9, 1fr);
    margin-right: 32px;
    // grid-template-rows: 0.7fr 1fr 0.6fr 0.8fr 0.8fr 0.7fr 0.6fr 1fr;
    @media (max-width: 800px) {
      grid-column: 1/8;
      grid-row: 2/3;
      margin-left: 1rem;
      margin-right: 8px;
    }
    p {
      border-right: 3px solid white;
      @media (max-width: 800px) {
        border-left: 3px solid white;
        border-right: none;
        padding-right: 0;
      }
    }
  }
  .first {
    grid-row: 2/3;
    align-self: center;
    justify-self: center;
  }
  .second {
    grid-row: 4/5;
    align-self: center;
    justify-self: center;
  }
  .third {
    grid-row: 6/7;
    align-self: center;
    justify-self: center;
  }
  .fourth {
    grid-row: 8/9;
    align-self: center;
    justify-self: center;
  }
  .fifth {
    grid-row: 10/11;
    align-self: center;
    justify-self: center;
  }
  .first1 {
    grid-row: 1/2;
    align-self: center;
    justify-self: center;
  }
  .second1 {
    grid-row: 3/4;
    align-self: center;
    justify-self: center;
  }
  .third1 {
    grid-row: 5/6;
    align-self: center;
    justify-self: center;
  }
  .fourth1 {
    grid-row: 7/8;
    align-self: center;
    justify-self: center;
  }
  .fifth1 {
    grid-row: 9/10;
    align-self: center;
    justify-self: center;
  }
}

.email {
  color: white;
  margin-bottom: 5rem;
}
