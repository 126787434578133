.container {
  width: 100%;
  text-align: left;
  margin: 5rem 0;
  margin-bottom: 0px;
  color: white;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  h1 {
    grid-column: 2/8;
    border-bottom: 3px solid white;
    padding-left: 2rem;
  }

  .faqItem {
    grid-column: 3/11;
    font-family: var(--main-font);
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 38px;
    text-align: left;
    padding: 2rem 2rem;
    padding-bottom: 0;
    margin: 0;
    color: #cecece;
    @media (max-width: 750px) {
      grid-column: 1/13;
      line-height: 28px;
      padding: 1rem 1rem;
      padding-bottom: 0;
    }

    .questionBox {
      border-left: 3px solid white;
      border-bottom: 3px solid white;
      padding-left: 1rem;
      padding-right: 1rem;
      width: 80%;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media (max-width: 750px) {
        width: 93%;
      }
      p {
        margin: 0.5rem;
      }

      .closeImg {
        height: 20px;
        width: 20px;
        transition: transform 0.3s ease-out;
      }
      .openImg {
        height: 20px;
        width: 20px;
        transition: transform 0.3s ease-out;
        transform: rotate(45deg);
      }
    }

    div {
      transition: all 0.2s ease-out;
    }
  }
}

.close {
  font-size: 20px;
  height: 0;
  padding: 1rem;
  opacity: 0;
  padding-left: 0;
}

.open {
  font-size: 20px;
  padding: 1rem;
  padding-left: 0;
  height: unset;
  opacity: 1;
}

.paragon {
  height: 500px;
  width: 500px;
  background-image: url("../assets/faq_para.png");
  background-position: center;
  background-size: contain;
  position: relative;
  margin: auto;
}
