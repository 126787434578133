.container {
  background-color: rgba(21, 24, 29, 0.644);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  width: 100%;
  backdrop-filter: blur(8px);
  opacity: 0;
  transition: opacity 0.3s ease-out;
  z-index: -1;

  small {
    margin-left: -60px;
  }

  ul {
    margin-top: 120px;
    padding-left: 8px;
    li {
      border-bottom: 1px solid rgba(255,255,255,0.5);
      display: flex;
      width:80%;

      justify-content: space-between;
      font-size: 1.5rem;
      font-family: var(--main-font);
      text-transform: uppercase;
      font-weight: bold;
      a {
        width: 100%;
        height: 100%;
        padding: 0.75rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        transition: color 0.1s ease-out;
        color: white;
        text-decoration: none;
        &:hover {
          color: #a933cf;
        }

        img {
          height: 30px !important;
          width: 30px !important;
        }
      }
      p {
        cursor:pointer;
        width: 100%;
        height: 100%;
        padding: 0.75rem;
        margin:0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        transition: color 0.1s ease-out;
        color: white;
        &:hover {
          color: #a933cf;
        }
      }
    }
  }
}

.fadeIn {
  opacity: 1;
  z-index: 1;
}
