.container {
  position: fixed;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  bottom: 0;
  width: 100%;
  height: 80px;
  z-index: 3;

  a {
    margin-left: 2rem;
  }

  img {
    width: 25px;
    transition: transform 0.1s ease-out;

    &:hover {
      transform: scale(1.07);
    }
  }
}
